import React from 'react';
import {Switch,Route,Redirect} from 'react-router-dom';
import './App.css';
import Landing from './Components/Landing/Landing';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import BusinessIntelligence from './Components/BusinessIntelligence/BusinessIntelligence';
import CustomerRelationshipManagment from './Components/CustomerRelationshipManagment/CustomerRelationshipManagment';
import DigitalTrasformation from './Components/DIGITALTRANSFORMATION/DigitalTrasformation';
import ApplicationDevelopment from './Components/ApplicationDevelopment/ApplicationDevelopment';
import Featured from './Components/Featured Services/Featured';
import TechnologyPartners from './Components/TechnologyPartners/TechnologyPartners'
import PrivacyPolicy from './Components/Privacy/Privacy';
import Termandconditions from './Components/Termandconditions/Termandconditions';
function App() {
  return (
      <>
      <Switch>
      <Route exact path="/" component={Landing} />
        <Route path="/About" component={About} />
        <Route path="/Contact" component={Contact} />
        <Route path="/BusinessIntelligence" component={BusinessIntelligence} />
        <Route path="/CustomerRelationshipManagment" component={CustomerRelationshipManagment} />
        <Route path="/DigitalTrasformation" component={DigitalTrasformation} />
        <Route path="/ApplicationDevelopment" component={ApplicationDevelopment} />
        <Route path="/Featured" component={Featured} />
        <Route path="/TechnologyPartners" component={TechnologyPartners} />
        <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route path="/Termandconditions" component={Termandconditions} />
        <Redirect to="/" />
      </Switch>
      </>
  );
}

export default App;