import React from "react";
import Logo from "../Assets/Img/5-5-3.png";
import "../Assets/Css/style.css";
import $ from "jquery";
import { Link } from "react-router-dom";

function Header(props) {
  if (props.navbar) {
    console.log(props.navbar);
  }
  function Scrolldown() {
    window.scroll(0, 0.1);
  }

  window.onload = Scrolldown;
  window.onscroll = function () {
    myFunction();
  };
  var header = $("sidemenu");
  var sticky = header.offsetTop;
  function myFunction() {
    if (window.pageYOffset > sticky) {
      $("#sidemenu").removeClass("active");
    }
    $("#sidebarCall").click(function () {
      window.$("#sidemenu").addClass("active");
    });
    $("#close").click(function () {
      window.$("#sidemenu").removeClass("active");
    });
  }
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light p-lg-4">
        <a class="navbar-brand" href="#Xoogle">
          <img
            src={Logo}
            alt="prince"
            class="img-fluid animate__animated animate__pulse"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto  animate__animated animate__fadeInRight">
            <li class="nav-item">
              <Link to="/" class="nav-link">
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/About" class="nav-link">
                About Us
              </Link>
            </li>

            <li class="nav-item dropdown">
              <Link
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </Link>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link class="dropdown-item" to="/BusinessIntelligence">
                  Business Intelligence & Data Analytics
                </Link>
                <Link class="dropdown-item" to="/CustomerRelationshipManagment">
                  
                  Customer Relationship Management
                </Link>
                <Link class="dropdown-item" to="/DigitalTrasformation">

                  Digital Transformation in Marketing
                </Link>
                <Link class="dropdown-item" to="/ApplicationDevelopment">
                  Business Process Engine / Application Development
                </Link>
                <Link class="dropdown-item" to="/Featured">
                  Featured Services
                </Link>
              </div>
            </li>
            <li class="nav-item">
              <Link to="/TechnologyPartners" class="nav-link">
                Technology Partners
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/Contact" class="nav-link">
                Contact Us
              </Link>
            </li>
            {/* <li class="nav-item">
                            <a class="nav-link " href="#Xoogle" id="sidebarCall" style={{"cursor":"pointer"}}><i class='bx bx-menu-alt-left'></i></a>
                        </li> */}
          </ul>
        </div>
      </nav>
      <div class="container-fluid" id="sidemenu">
        <a href="#Xoogle" class="close mt-5 text-white" id="close">
          <i class="bx bxs-chevron-right"></i>
        </a>
        <div class="container mt-5">
          <nav class=" mt-5">
            <ul class="">
              <li>
                <i class="bx bxs-home"></i> Home
              </li>
              <li>
                <i class="bx bxs-category"></i> About Us
              </li>
              <li>
                <i class="bx bx-notification"></i> Catalog
              </li>
              <li id="Openprofile">
                <i class="bx bx-user"></i> Profile
              </li>
              <li>
                <i class="bx bxs-data"></i> Register / Login
              </li>
              <li>
                <i class="bx bx-comment-minus"></i> Contact us
              </li>
            </ul>
          </nav>

          {/* <div class="container-fluid mt-5" id="footerSec">
                        <h1>Follow Us On</h1>
                        <a><i class='bx bxl-linkedin-square'></i></a>
                        <a><i class='bx bxl-facebook-square'></i></a>
                        <a><i class='bx bxl-twitter'></i></a>
                        <a><i class='bx bxl-instagram'></i></a>
                    </div> */}
        </div>
      </div>
    </>
  );
}

export default Header;
