import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Man from "../Assets/Img/undraw_businessman_97x4.svg";
import Sales from "../Assets/Img/undraw_phone_call_grmk.svg";
import Finance from "../Assets/Img/undraw_investing_7u74.svg";
import Logo from "../Assets/Img/5-5-3.png";
import "../BusinessIntelligence/business.css";
import $ from 'jquery';
function BusinessIntelligence() {
  $(document).ready(function(){
    $(this).scrollTop(0);
});
function linkPage()
{
  window.open("https://www.linkedin.com/company/xoogle-global-technologies-pvt-ltd/", "_blank");
}
  return (
    <>
      <div class="container-fluid" id="businessSection1">
        <Header />
        <div class="container">
          <h1>
            BUSINESS INTELLIGENCE & <span>DATA ANALYTICS</span>
          </h1>
          <p>
            Xoogle provide Data analytics implementation by using various data
            storage tools available in the market and BI tools to execute our IT strategies.
          </p>
          <div class="row mt-5">
            <div class="col-lg-6 p-lg-5">
              <img src={Man} class="img-fluid" alt="Xoogle" />
            </div>
            <div class="col-lg-6">
              <p class="mt-5">
                Business Intelligence deals with complex strategies and
                technologies that help end-users in analyzing the data and
                perform decision-making activities to grow their business. BI
                plays a key role in business data management and performance
                management. Data analytics, on the other hand, is implemented to
                convert the raw or unstructured data into a user understandable
                meaningful data format. The transformed information can be
                utilized to cleanse, transform or model the data to support the
                process of decision making, derive conclusions and implement
                predictive analytics.
              </p>
              <p>
                Xoogle helps you in the enterprise users to transform the raw or
                unstructured data into a meaningful format. Data Analytics is a
                standard or common process that is deployed under various
                procedures or strategies by many organizations around the globe
                depending upon their business needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="action shadow-lg" onClick={linkPage} >
        <span>

        </span>
            </div>
      <div class="container-fluid mt-5" id="businessSection2">
        <div class="container">
          <h3>
            BI IS ABOUT PROVIDING THE RIGHT DATA AT THE RIGHT TIME TO THE RIGHT
            PEOPLE SO THAT THEY CAN TAKE THE RIGHT DECISIONS
          </h3>
          <hr />
          <div class="row">
            <div class="col-lg-6 p-lg-5">
              <h1 class="mt-3">IT</h1>
              <p>
                In the fields of information technology (IT) and systems
                management, IT operations analytics (ITOA) is an approach or
                method to retrieve, analyze, and report data for IT operations.
                ITOA may apply big data analytics to large datasets to produce
                business insights.
              </p>
            </div>
            <div class="col-lg-6 p-lg-5">
              <img src={Sales} class="img-fluid" alt="Xoogle" />
            </div>
            <div class="col-lg-6 p-lg-5">
              <img src={Finance} class="img-fluid" alt="Xoogle" />
            </div>
            <div class="col-lg-6 p-lg-5">
              <h1 class="mt-5">HR</h1>
              <p>
                Human Resource analytics (HR Analytics) is defined as the area
                in the field of analytics that deals with people analysis and
                applying analytical process to the human capital within the
                organization to improve employee performance and improve
                employee retention.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="businessSection4">
        <div class="row">
          <div class="col-lg-4 p-lg-5">
            <img src={Logo} class="img-fluid" alt="Xoogle" />
          </div>
          <div class="col-lg-4 p-lg-5">
            <h3>
              BI is about providing the right data at the right time to the
              right people so that they can take the right decisions.
            </h3>
          </div>
          <div class="col-lg-4 text-center p-lg-5">
            <button class="btn btn-block mt-5">Contact Us</button>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="businessSection3">
        <div class="container p-lg-5">
          <div class="row text-center">
            <div class="col-lg-3">
              <p>
                Sales & Marketing data to forecast, Budget and ultimately drive
                and deliver the forecasted and budgeted numbers using advanced
                analytics.
              </p>
            </div>
            <div class="col-lg-3 order-first">
              <i class="bx bx-trip"></i>
              <h1>SALES</h1>
            </div>
            <div class="col-lg-3">
              <i class="bx bxs-category-alt"></i>
              <h1>FINANCE</h1>
            </div>
            <div class="col-lg-3">
              <p>
                With the help of financial analytics we help organisations to
                information decisions & drive operational efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-1" id="businessSection3">
        <div class="container p-lg-5">
          <div class="row text-center">
            <div class="col-lg-3">
              <p>
                Using data analysis and business intelligence to improve
                efficiency and streamline everyday operations in real time.
              </p>
            </div>
            <div class="col-lg-3 order-first">
              <i class="bx bx-braille"></i>
              <h1>OPERATIONS</h1>
            </div>
            <div class="col-lg-3">
              <i class="bx bx-minus-back"></i>
              <h1>SCM</h1>
            </div>
            <div class="col-lg-3">
              <p>
                Latest Technologies help provide different kind of supply chain
                analytics: Descriptive analytics, Predictive analytics,
                Prescriptive analytics & Cognitive analytics.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BusinessIntelligence;