import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Logo from '../Assets/Img/5-5-3.png';
import {Link} from 'react-router-dom'
import CRM from '../Assets/Img/undraw_customer_survey_f9ur.svg';
import '../CustomerRelationshipManagment/crm.css'
import $ from 'jquery'
function CustomerRelationshipManagment() {
    $(document).ready(function(){
        $(this).scrollTop(0);
    });
    function linkPage()
{
  window.open("https://www.linkedin.com/company/xoogle-global-technologies-pvt-ltd/", "_blank");
}
    return (
        <>
            <div class="container-fluid" id="CustomerSection1">
                <Header />
                <div class="container">
                    <h1>Customer Relationship <span>Management</span></h1>
                    <p>We work as an end-to-end solution provider of an application program, software and system software as well as hardware requirements of the customer such that it helps in seamless installation, integration, and setup.</p>
                    <div class="row">
                        <div class="col-lg-6 p-lg-5 order-second">
                            <p>The better a business can manage the relationships it has with its customers the more successful it will become. Therefore IT systems that specifically address the problems of dealing with customers on a day-to-day basis are growing in popularity.</p>
                            <p>Customer relationship management (CRM) is not just the application of technology, but is a strategy to learn more about customers’ needs and behaviours in order to develop stronger relationships with them. As such it is more of a business philosophy than a technical solution to assist in dealing with customers effectively and efficiently. Nevertheless, successful CRM relies on the use of technology.</p>
                        </div>
                        <div class="col-lg-6 p-lg-5 order-first">
                            <img src={CRM} class="img-fluid" alt="Xoogle" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="action shadow-lg" onClick={linkPage} >
        <span>

        </span>
            </div>
            <div class="container-fluid mt-5" id="CustomerSection2">
                <div class="container">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">End to End Implementation</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Training Services</a>
                        </li>
                    </ul>
                    <div class="tab-content mt-3" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <p>We help enterprises in setting up their customer relationship management suite, as per the business needs and technical landscape. This includes consulting, development, integration and support.</p>
                        </div>
                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <p>Our training solutions are developed by subject matter experts to help organisations and individuals improve personal competence and skills – core enablers of sustainable business development and competitive advantage.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-5" id="CustomerSection3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 p-lg-4">
                            <i class='bx bxs-category-alt'></i>
                            <h2>CONSULTING SERVICES</h2>
                            <p>Our consulting services focus on our clients' most critical issues and opportunities: strategy, marketing, organization, operations, technology, transformation and digital advanced analytics.</p>
                        </div>
                        <div class="col-lg-4 p-lg-4">
                            <i class='bx bx-bookmark-alt-minus' ></i>
                            <h2>SUPPORT AND MIGRATION</h2>
                            <p>Migration Support Services provide two extended support options to help you mitigate risk as you migrate to products nearing LDoS to new technologies</p>
                        </div>
                        <div class="col-lg-4 p-lg-4">
                            <i class='bx bx-minus-back' ></i>
                            <h2>SYSTEM INTEGRATIONS</h2>
                            <p>We assist enterprises in integrating their business process automation systems with their legacy systems to have a seamless flow to data.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mb-0" id="CustomerSection4">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 p-lg-5">
                            <img src={Logo} class="img-fluid" alt="Xoogle" />
                            <p>You don’t close a sale, you open a relationship if you want to build a long-term, successful enterprise.
                            The better a business can manage the relationships it has with its customers the more successful it will become. Therefore IT systems that specifically address the problems of dealing with customers on a day-to-day basis are growing in popularity.
                                    </p>
                            <Link to="/Contact" class="btn btn-block mt-3">Contact Us</Link>
                        </div>
                        <div class="col-lg-6 p-0">
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}
export default CustomerRelationshipManagment;