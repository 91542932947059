import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../TechnologyPartners/Technology.css";
import Freshworks from "../Assets/Img/freshworks-logo.jpg";
import Join from "../Assets/Img/1615226073281.jpeg";
import Mongo from "../Assets/Img/mongo.jpg";
import Klip from "../Assets/Img/klipfolio-logo.jpg";
import google from "../Assets/Img/google.jpg";
import talendlogo from '../Assets/Img/talend-logo.jpg';
import zendesk_partner from '../Assets/Img/zendesk-partner.jpeg';
import zendesk_logo from '../Assets/Img/Zendesk-Logo.png';
import $ from "jquery";
function TechnologyPartners() {
  $(document).ready(function () {
    $(this).scrollTop(0);
  });
  function linkPage() {
    window.open("https://www.linkedin.com/company/xoogle-global-technologies-pvt-ltd/", "_blank");
  }
  return (
    <>
      <div class="container-fluid" id="section1Technology">
        <Header />
        <div class="container p-5">
          <h1>Technology Partners</h1>
          <p>
            At its most fundamental level, process excellence is not about a
            methodology, it is about improving the way that businesses create
            and deliver value to their customers.
          </p>
          {/* <a href="#section3Technology" class="btn" >
            <i class="bx bx-down-arrow-alt"></i>
          </a> */}
        </div>
      </div>
      <div class="action shadow-lg" onClick={linkPage} >
        <span>

        </span>
      </div>
      <div className="container-fluid" id="zendesk">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img src={zendesk_logo} alt="zendesk-logo" className="img-fluid" />
                <h3>Champions of Customer Service</h3>
                <p> It provides software-as-a-service products related to customer support, sales, and other customer communications. </p>
                <a href="https://www.zendesk.com/in/about/" target="_blank" rel="noopener noreferrer" class="btn btn-primary">Learn More</a>
              </div>
              <div className="col-lg-6 p-lg-3 text-center">
                <img src={zendesk_partner} alt="xoogle-logo" className="img-fluid" />
              </div>
            </div>
          </div>
      </div>
      <div class="container-fluid" id="section2Technology">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 p-lg-3 text-center">
              <img src={Freshworks} height="100px" alt="Xoogle" />
              <h1>BUSINESS PRODUCT SUITE</h1>
              <p>
                Freshworks Customer Relationship Management, making it easy for
                teams to acquire, close, and win customers for life.
              </p>
              <a href="https://www.freshworks.com/" target="_blank" rel="noopener noreferrer" class="btn btn-primary">Learn More</a>
            </div>
            <div class="col-lg-6 p-0">
              <img src={Join} class="img-fluid" alt="Xoogle" />
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="mongo">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 p-lg-5">
              <h1>THE CLOUD-NATIVE DATABASE</h1>
              <p>
                MongoDB is a cross-platform document-oriented database program.
              </p>
            </div>
            <div class="col-lg-6">
              <img src={Mongo} class="img-fluid" alt="Xoogle" />
              <br />
              <a href="https://www.mongodb.com" class="btn btn-primary" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="mongo">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 p-lg-5">
              <h1>CLOUD COMPUTING SERVICES</h1>
              <p>
                Manage Google Ads accounts on behalf of other brands or businesses
              </p>
            </div>
            <div class="col-lg-6">
              <img src={google} class="img-fluid" alt="Xoogle" />
              <br />
              <a href="https://cloud.google.com" class="btn btn-primary" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="mongo">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 p-lg-5">
              <h1>BUSINESS DASHBOARD SOFTWARE</h1>
              <p>
                Online dashboard platform for building powerful real-time business dashboards for your team or your clients.
              </p>
            </div>
            <div class="col-lg-6">
              <img src={Klip} class="img-fluid" alt="Xoogle" />
              <br />
              <a href="https://www.klipfolio.com" class="btn btn-primary" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid" id="mongo">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 p-lg-5">
              <h1>CLOUD DATA INTEGRATION</h1>
              <p>
                Data integration and data integrity to help enterprises collect, govern, transform, and share data.
              </p>
            </div>
            <div class="col-lg-6">
              <img src={talendlogo} class="img-fluid" alt="Xoogle" />
              <br />
              <a href="https://www.talend.com" class="btn btn-primary" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid" id="section3Technology">
        <div class="row">
          <div class="col-lg-6 order-second" id="section3TechnologyPart"></div>
          <div class="col-lg-6 p-lg-5 order-first">
            <h1 class="mt-5">Technologies we use</h1>
            <p>
              Xoogle strives to be the best in class provider of software
              development services in India and globally. It has implemented and
              achieved ISO9001 & 27001 certifications. Everyone at Xoogle is
              dedicated to complete customer satisfaction by exceeding their
              expectations.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default TechnologyPartners;
