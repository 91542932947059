import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../Featured Services/Featured.css";
import Image from "../Assets/Img/undraw_interaction_design_odgc.svg";
import $ from "jquery"
function Featured() {
  $(document).ready(function(){
    $(this).scrollTop(0);
});
function linkPage()
{
  window.open("https://www.linkedin.com/company/xoogle-global-technologies-pvt-ltd/", "_blank");
}
  return (
    <>
      <div class="container-fluid" id="FeaturedSection1">
        <Header />
        <div class="container">
          <h1>
            <span>FEATURED</span> SERVICES
          </h1>
          <p>
            Mobile apps are now an integral part of digital strategy and the
            demand of mobile apps is increasing rapidly around the globe and we
            can find millions of Business apps, lifestyle apps, educational
            apps, on-demand apps etc on the Google Play Store and the App Store.
            Technology in the area of mobile app development is getting advanced
            day by day. Number of new trends have been introduced this year.
          </p>
          <div class="row">
            <div class="col-lg-6 p-lg-5">
              <ul class="mt-5">
                <li>Artificial Intelligence</li>
                <li>Augmented Reality & Virtual Reality</li>
                <li>Cognitive cloud Computing</li>
                <li>Application development using contemporary technologies</li>
              </ul>
              <p>
                In an era where new, and completely new technology is scarce, we
                found Artificial Intelligence to work on. We assist enterprise
                in their business process automation journey by delivering
                robust applications using latest technology framework.
              </p>
            </div>
            <div class="col-lg-6">
              <img src={Image} class="img-fluid" alt="Xoogle" />
            </div>
          </div>
        </div>
      </div>
      <div class="action shadow-lg" onClick={linkPage} >
        <span>

        </span>
            </div>
      <div class="container-fluid" id="FeaturedSection2">
        <div class="container">
          <h1><span>Artificial Intelligence</span></h1>
          <ul>
            <li>
              AI existed even before the internet was born, but it is now that
              the data processing and compute power backbone became strong
              enough to sustain an entire technology by itself.
            </li>
            <li>
              AI is everywhere today, from your smartphones to your cars to your
              home to your banking establishment.
            </li>
            <li>
              It is the new normal, something the world cannot do without.
            </li>
          </ul>
          <h1 class="mt-5"><span>Augmented Reality & Virtual Reality</span></h1>
          <ul>
              <li>Virtual is real! VR and AR, the twin technologies that let you experience things in virtual, that are extremely close to real, are today being used by businesses of all sizes and shapes. But the underlying technology can be quite complex.</li>
                <li>Medical students use AR technology to practice surgery in a controlled environment.</li>
              <li>VR on the other hand, opens up newer avenues for gaming and interactive marketing.</li>
              </ul>
              <h1 class="mt-5"><span>Cognitive cloud Computing</span></h1>
              <ul>
                  <li>Cognitive Cloud is an extended ecosystem of traditional Cloud and Cognitive Computing.</li>
                  <li>It’s due to this, you can create Cognitive Computing applications and bring to the masses through cloud deployments. Cognitive computing is considered as the next big evolution in the IT industry.</li>
                  <li>It converses in human language and helps experts in better decision making by understanding the complexities of Big Data. Its market size is expected to generate revenue of $13.8 billion by 2020 and is one of the top 10 trending technologies to consider this year.</li>
                  <li>Big brands such as IBM, Google, Microsoft, Cisco have already started implementing this next-gen tech to gear up for the upcoming market.</li>
                  </ul>
                  <h1 class="mt-5"><span>Application development using contemporary technologies</span></h1>
                  <ul>
                      <li>Angular and React are JavaScript based Frameworks for creating modern web applications.</li>
                      <li>Using React and Angular one can create a highly modular web app. So, you don’t need to go through a lot of changes in your code base for adding a new feature.</li>
                      <li>Angular and React also allows you to create a native mobile application with the same JS, CSS & HTML knowledge</li>
                      <li>Best part – Open source library with highly active community support.</li>
                      </ul>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Featured;
