import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./privacy.css";
import $ from "jquery";

function PrivacyPolicy() {
  $(document).ready(function () {
    $(this).scrollTop(0);
  });
  return (
    <>
      <div class="container-fluid" id="secPrivacy">
        <Header />
        <div class="container p-lg-5">
          <h1><span>Privacy Policy</span></h1>
          <br />
          <h2>Introduction</h2>
          
          <p>
          At Xoogle, we are committed to protecting the privacy of your personal information while using our websites, products or services. In this policy, Xoogle sets out how we collect and use personally identifiable information and personal data, including information that you provide to us.
Sometimes, we do need information to provide services that you request and this statement of privacy explains data collection and use in those situations. Xoogle Global Technologies Pvt. Ltd. and its affiliated entities are collectively referred to in this Policy as “Xoogle” “we,” or “us.” Please read the complete Xoogle Privacy Policy. By using our website, you consent to the collection, sharing and use of information as described here.
          </p>
          <h2>Policy Scope</h2>
          <p>This Privacy Policy applies only to personal information collected by Xoogle through its website or other marketing activities. This policy is posted on the website and does not apply to any other information collected by Xoogle through other means. If you have other agreements with Xoogle governing Xoogle products and services, then those agreements terms are in force with regard to the subject matter of those agreements.
As a convenience to our site visitors, we may provide links to other web sites that are not governed by this privacy policy. These linked sites are not under the control of Xoogle, and we are not responsible for the protection and privacy of any information which you provide whilst visiting such sites.</p>
            <h2>Collecting your Personal Information</h2>
            <p>Personal information is information that identifies you individually, such as your name, address, email address, company name, phone number, or other identifying information that is specific to you.
Personal information is collected by Xoogle on our websites, for example when you register for such items as events, newsletters, reports, software or documentation downloads, or discussion groups. Personal information collected by Xoogle often is limited to your name, email address, language, country or location, but may include other information when needed to provide a service you requested or to support your use of our products. Xoogle may also obtain personal data from third-parties.
Xoogle may collect and use non-personalized information (mouse clicks, etc.) on an aggregate, anonymized basis for the purpose of monitoring and improving the Site. We may also automatically collect and store certain anonymized information such as: statistics on your activities on the Site (date, time, pages viewed, language preferences and length of time on our Site); information about how you came to and used the Site; your Internet Protocol (IP) address/MAC address/device identifier, broad geographic location (e.g., country or city-level location) and other technical data collected through cookies, pixel tags and other similar technologies that uniquely identify your browser.</p>

        <h2>Use of your Personal Information</h2>
        <p>We may use the personal information we collect from you to:</p>
        <ul class="ml-3">
            <li>make the site easier for you to use by not making you enter your personal information more than once.</li>
            <li>help you quickly find software, services or information.</li>
            <li>provide you with products or services that you have ordered;</li>
            <li>help us create and deliver content most relevant to you through location customization, personalized help and instructions, and other ways to personalize your experiences while using the website;</li>
            <li>Contact you to promote our products and services, as well as to alert you to product upgrades, special offers, updated information and other new services from Xoogle.</li>
            <li>improve your user experience with our websites, products and services both on an aggregated and individualized basis;</li>
            <li>help us troubleshoot any problems you might experience with our services;</li>
            <li>if you apply for a job vacancy on the Site, to assess your application and communicate with you about this;</li>
            <li>Comply with applicable legal requirements, relevant industry standards and our policies.</li>
            </ul>
            <p>Xoogle will not use personal information in a way that is incompatible with the purposes for which it has been collected or subsequently authorized by the individual.</p>
            <p>We may periodically contact you by with promotional or news material about our company and our products which we think you may find interesting using the email address or other contact details which you have provided.</p>
            
            <h2>Security</h2>
            <p>Xoogle will employ reasonable precautions to protect personal information from loss, misuse and unauthorized access, disclosure, alteration or destruction. Such measures may include restricting access to personal information to those with a legitimate purpose in receiving the information.
Xoogle takes reasonable steps to help ensure that the personal information is reliable for its intended use, accurate, complete and current. Reasonable steps shall also be taken to accommodate privacy preferences, such as restricting access to the personal information to those who have a legitimate business need to know the information, anonymizing certain information, or assigning codes or pseudonyms when the actual names are not required for the purpose at hand.
Employees who have access to such personal information shall be trained regarding this Policy, advised that they are responsible for fully complying with the privacy principles articulated in this Policy and instructed that violations of these principles shall result in appropriate discipline up to and including termination.
While we take reasonable precautions against possible security breaches of our site and records no website or Internet transmission is completely secure and we cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur.</p>
        
        
          <h2>Contact Information</h2>
          <p>To keep your Personal Data accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Site.</p>
          <p>Xoogle Global Technologies Pvt. Ltd.<br/>
B905, Advant Navis Tech Park<br/>
Sector – 142, Noida
U.P.
</p>
</div>
  </div>
      <Footer />
    </>
  );
}
export default PrivacyPolicy;
