import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../DIGITALTRANSFORMATION/Digital.css";
import Laptop from "../Assets/Img/joshua-reddekopp-SyYmXSDnJ54-unsplash.jpg";
import SEO from '../Assets/Img/avel-chuklanov-9OWS4lT5iqQ-unsplash.jpg'
import conatctNew from '../Assets/Img/conatctNew.png';
import repu from '../Assets/Img/contactNext.png';
import brand from '../Assets/Img/hunters-race-MYbhN8KaaEc-unsplash.jpg';
import sem from '../Assets/Img/technew.png'
import $ from "jquery"
function DigitalTrasformation() {
  $(document).ready(function(){
    $(this).scrollTop(0);
});
function linkPage()
{
  window.open("https://www.linkedin.com/company/xoogle-global-technologies-pvt-ltd/", "_blank");
}
  return (
    <>
      <div class="container-fluid mb-5" id="digitalSection1">
        <Header />
        <div class="container">
          <h1>
            DIGITAL <span>TRANSFORMATION</span> IN MARKETING
          </h1>
          <p>
            The basic idea with a Digital transformation strategy is to analyze
            your own needs and your own company culture & to set business
            objectives & understand and document the risks, to run frequent
            pilots and tests, to ask internal employees for feedback about those
            roll-outs and to proceed with the new technologies.
          </p>
          <div class="row mt-5">
            <div class="col-lg-4">
              <div class="card">
                <img src={SEO} class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Social Media Management</h5>
                  <p class="card-text">
                    Social media management is the process of creating,
                    scheduling, analyzing, and engaging with content posted on
                    social media platforms, Management tools and services can
                    help you reach this huge audience and make running a social
                    media campaign  simpler.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <img src={conatctNew} class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">WEB DEVELOPMENT</h5>
                  <p class="card-text">
                    Website is the face of organizations. People will look
                    at your website while doing their research. It has to be
                    unique and perfect. People visiting your website should get
                    what they are looking for. Every website needs a good
                    content management system (CMS).
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <img src={repu} class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Online Reputation Management</h5>
                  <p class="card-text">
                    Your online reputation determines how others perceive your
                    business when they search for or stumble upon it online.
                    Consequently, online reputation management (ORM) proactively
                    influences what information people will find.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3" id="secondCard">
            <div class="col-lg-4">
              <div class="card">
                <img src={brand} class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">Branding & Strategy</h5>
                  <p class="card-text">
                    Branding strategies deal with creating brand names, logos,
                    style etc. for it to be distinguished from competitors and
                    also whether product brand should be separate from corporate
                    brand or a separate brand away from other individual brands.
                    it also ensure to differentiate your brand from the patharo of corporate or individual brands.impact of branding strategies is to create brand awareness.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <img src={Laptop} class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">
                    Graphics – Creatives, Infographics & UI Design
                  </h5>
                  <p class="card-text">
                    Graphic design is all around us, in a myriad of forms, both
                    on screen and in print, yet it is always made up of images
                    and words to create a communication goal. Visual design was
                    born out of a mixture of graphic design and user interface
                    (UI) design. It focuses on the aesthetics of a website or
                    any other type of digital design. Does the finished product
                    look good? That is the question visual designer aim to
                    answer.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <img src={sem} class="card-img-top" alt="..." />
                <div class="card-body">
                  <h5 class="card-title">SEO & SEM</h5>
                  <p class="card-text">
                    SEO is increasing the amount of website visitors by getting
                    the site to appear high on results returned by a search
                    engine. SEM is considered internet marketing that increases
                    a site’s visibility through organic search engines results
                    and advertising. SEM includes SEO as well as other search
                    marketing tactics. We also provide ASO because every online
                    business requires ASO services to get downloads flowing and
                    proper traffic to their app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="action shadow-lg" onClick={linkPage} >
        <span>

        </span>
            </div>
      <Footer />
    </>
  );
}

export default DigitalTrasformation;
