import React from 'react';
import Logo2 from '../Assets/Img/Xoogle _Logo.png'
import '../Assets/Css/style.css'
import { Link } from "react-router-dom";
function Footer() {
    return (
        <>
            <div class="container-fluid " id="section10">
                <div class="row p-lg-5">
                    <div class="col-lg-3">
                        <img src={Logo2} class="img-fluid" alt="" />
                        <p>XOOGLE, A Technology transformation & Innovative Solutions Company dedicated to offer 360 degrees Customer delight.</p>
                    </div>
                    <div class="col-lg-3">
                        <h2>Company</h2>
                        <ul>
                           <Link to="/"> <li><i class='bx bxs-right-arrow' ></i> Home</li></Link>
                           <Link to="/About"><li><i class='bx bxs-right-arrow' ></i> About Us</li></Link>
                           <Link to="/CustomerRelationshipManagment"><li><i class='bx bxs-right-arrow' ></i> Our Services</li></Link>
                           <Link to="/TechnologyPartners"><li><i class='bx bxs-right-arrow' ></i> Partners</li></Link>
                           <Link to="/Contact"><li><i class='bx bxs-right-arrow' ></i> Contact Us</li> </Link>
                           <Link to="/PrivacyPolicy"><li><i class='bx bxs-right-arrow' ></i> Privacy Policy</li> </Link>
                           <Link to="/Termandconditions"><li><i class='bx bxs-right-arrow' ></i> Terms and conditions</li> </Link>
                        </ul>
                    </div>
                    <div class="col-lg-3">
                        <h2>Quick Links</h2>
                        <ul>
                        <Link  to="/BusinessIntelligence"><li><i class='bx bxs-right-arrow' ></i> Business Intelligence</li></Link>
                           <Link to="/CustomerRelationshipManagment"> <li><i class='bx bxs-right-arrow' ></i> Customer Relationship</li></Link>
                           <Link to="/DigitalTrasformation"><li><i class='bx bxs-right-arrow' ></i> Digital Trasformation</li></Link>
                           <Link to="/BusinessIntelligence"><li><i class='bx bxs-right-arrow' ></i> Business Process</li></Link>
                           <Link to="/ApplicationDevelopment"><li><i class='bx bxs-right-arrow' ></i> Application Development</li></Link>
                           <Link to="/Featured"><li><i class='bx bxs-right-arrow' ></i> Featured Services</li></Link>
                        </ul>
                    </div>
                    <div class="col-lg-3">
                        <h2>Contact</h2>
                        <p>Address: Xoogle Global Technologies Pvt Ltd, Advant Navis Business Park,Sector 142, Noida - 201305, India</p>
                        <ul>
                            <li>Phone: +91 120 4561770</li>
                            <li>Fax: +91 120 4561770</li>
                            <li>Email: contact@xoogle.in</li>
                            <li>Website: www.xoogle.co.in</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container-fluid p-3" id="section11">
                <h3>2021 © Copyright Xoogle</h3>
            </div>
        </>
    )
}
export default Footer;