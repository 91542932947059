import React from "react";
import Header from "../Header/Header";
import "../About/About.css";
import Footer from "../Footer/Footer";
import $ from "jquery";
function About() {
  $(document).ready(function () {
    $(this).scrollTop(0);
  });
  function linkPage()
{
  window.open("https://www.linkedin.com/company/xoogle-global-technologies-pvt-ltd/", "_blank");
}
  return (
    <>
      <div class="container-fluid" id="aboutSection1">
        <Header />
        <div class="container text-center">
          <h1>
            <span>About</span> Us
          </h1>
          {/* <p class="mt-4">
            We have evolved with every single project and have integrated the
            knowledge garnered in each project to enhance the technical know how
            and capabilities to maximize investment and improve cycle time.
            Xoogle Tech is sensitive & committed to safeguard customer’s
            complete data and information by deploying most reliable solutions.
          </p> */}
          {/* <button class="btn">Contact Us</button> */}
        </div>
      </div>
      <div class="action shadow-lg" onClick={linkPage} >
        <span>

        </span>
            </div>
      <div class="container-fluid" id="aboutSection2">
        <div class="row">
          <div class="col-lg-6 p-lg-5">
            <h1>
              ABOUT <span>XOOGLE</span>
            </h1>
            <p class="mt-4">
              We have evolved with every single project and have integrated the
              knowledge garnered to enhance the technical know how and
              capabilities to maximize investments and improve cycle times. We
              have expertise and experience in successfully transforming the
              technology landscape of organizations across major industry
              segments by helping to enhance the experience of each business
              function.
            </p>
          </div>
          <div class="col-lg-6 " id="aboutSection2part1"></div>
        </div>
      </div>
      <div class="container-fluid" id="aboutSection3">
        <div class="row">
          <div class="col-lg-6" id="aboutSection3part1"></div>
          <div class="col-lg-6 p-lg-5">
            <h1>
              Security <span>Culture</span>
            </h1>
            <p class="mt-4">
              Xoogle Tech is sensitive & committed to safeguard customer’s
              complete data and information by deploying most reliable solutions
              in collaboration with customer business requirements guided by
              relevant laws and regulations. The management is committed to
              deploy appropriate security within the organization and
              maintaining the security of the organization’s information and
              information processing facilities that are accessed, processed,
              communicated to, or managed by external parties. The security
              policy is executed and guided by the company’s core values,
              sensitivity to Internationally recognized data security, business
              ethics and ISO security standards. At Xoogle we have a sustainable
              security culture which is persistent and imbibed in everything we
              attempt and accomplish. At Xoogle security is a habit and a part
              of our culture with company allowing zero tolerance to any
              deviation.
            </p>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="aboutSection4">
        <div class="container text-center p-lg-5">
          <i class="bx bxs-diamond"></i>
          <h1>
            Our <span>Core</span> Values
          </h1>
          <p class="mt-4">
            With the Core Values People before Product & Service before Sales
            ingrained in the system keeps us on our toes to enhance the customer
            experience and maximise the investments. We strive to spend ample
            time to understand the clear requirement in all aspects and then
            design an impeccable solution addressing each and every aspect of
            the requirement. We are extremely sensitive that Time is Money and
            ensure to commit realistic and aggressive timelines to ensure to
            match with the Customer milestones.
          </p>
        </div>
      </div>
      <div class="container-fluid mb-5" id="aboutSection5">
        <div class="row text-center">
          <div class="col-lg-3">
            <i class="bx bxs-user-circle"></i>
            <h1>UNDERSTAND</h1>
            <p>
              Understand customer expectations and requirements to align to the
              minute nuances of the requirements coupled with the understanding
              of the complete ecosystem.
            </p>
          </div>
          <div class="col-lg-3">
            <i class="bx bxs-category"></i>
            <h1>SOLUTION</h1>
            <p>
              Empowered with the knowledge Xoogle will strive to customize a
              robust solution best fitting the customer’s requirements.
            </p>
          </div>
          <div class="col-lg-3">
            <i class="bx bx-braille"></i>
            <h1>LOYALTY</h1>
            <p>
              Achieve customer loyalty by innovative, technologically advanced,
              adaptive, customer centric solutions.
            </p>
          </div>
          <div class="col-lg-3">
            <i class="bx bxs-cabinet"></i>
            <h1>KAIZEN</h1>
            <p>
              Continually improve processes to deliver customer delight, ease of
              doing business and maximize growth.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
