import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Logo from "../Assets/Img/5-5-3.png";
import SVG2 from "../Assets/Img/undraw_teamwork_hpdk.svg";
import manImage from "../Assets/Img/feature-img-1.png";
import { Link } from "react-router-dom";
import $ from 'jquery';
function Landing() {
  $(document).ready(function(){
    $(this).scrollTop(0);
});
function linkPage()
{
  window.open("https://www.linkedin.com/company/xoogle-global-technologies-pvt-ltd/", "_blank");
}
  return (
    <>
      <div class="container-fluid" id="section1">
        <Header />
        <div class="container ">
          <div class="row">
            <div class="col-lg-6 p-lg-5 animate__animated animate__fadeInUp">
              <img src={Logo} class="img-fluid mt-5 " alt="Xoogle" />
              <h1>We do IT on Technology</h1>
              <h3 class="mt-3">Simple solutions for Complex IT transformation</h3>
              <hr />
              <Link to="/About" class="btn ml-3">More Details</Link>
              <Link to="/Contact" class="btn ml-3">Get in Touch</Link>
            </div>
          </div>
        </div>
      </div>
      <div class="action shadow-lg" onClick={linkPage} >
        <span>

        </span>
            </div>
      <div class="container-fluid" id="section2">
        <div class="row p-lg-5">
          <div class="col-lg-6 p-lg-5 animate__animated animate__fadeInLeft">
            <h1 class="mt-5">
              WHAT IS <span>XOOGLE</span>
            </h1>
            <p class="mt-5">
              XOOGLE, A Technology transformation & Innovative Solutions Company
              dedicated to offer 360 degrees Customer delight. We engage with
              the customer Leadership & Technology team to create a
              transformation roadmap coupled with an efficient project execution
              plan.
            </p>
            <p class="mt-3">
              Founded in 2015 we cater to global customers with base in India
              and South Africa. At Xoogle we are paranoid to exceed customer
              expectations every single time by offering cutting edge full stack
              development solutions & consulting services encompassing:
            </p>
            <div class="row">
              <div class="col-lg-6">
                <ul>
                  <li>
                    <i class="bx bxs-chevrons-right"></i> Application
                    development
                  </li>
                  <li>
                    <i class="bx bxs-chevrons-right"></i> Advanced Analytics
                  </li>
                  <li>
                    <i class="bx bxs-chevrons-right"></i> Business Intelligence
                  </li>
                  <li>
                    <i class="bx bxs-chevrons-right"></i> Business Process
                    Engine
                  </li>
                </ul>
              </div>
              <div class="col-lg-6">
                <ul>
                  <li>
                    <i class="bx bxs-chevrons-right"></i> Customer Relationship
                    Management
                  </li>
                  <li>
                    <i class="bx bxs-chevrons-right"></i> Digital Transformation
                    in Marketing
                  </li>
                  <li>
                    <i class="bx bxs-chevrons-right"></i> Training & Consulting
                    Services
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="section6">
        <h1 class="mt-5">
          Our <span>Practice</span> Pillars
        </h1>
        <p>
          We assist our customer in their end to end Digital Transformation
          journey by virtue of our holistic solution landscape.
        </p>
        <div class="row mt-3 p-lg-5 ">
          <div class="col-lg-3 p-lg-3 ">
            <i class="bx bxs-category-alt"></i>
            <h2>Branding Excellence</h2>
            <p>Brand Identity, Positioning & Promotion</p>
            <div class="number d-flex justify-content-center align-items-center">
              <p>01</p>
              <Link to="/DigitalTrasformation" class="ml-2">
                Read More
              </Link>
            </div>
          </div>
          <div class="col-lg-3 p-lg-3">
            <i class="bx bx-shape-polygon"></i>
            <h2>Data Excellence</h2>
            <p>Data Processing & Analysis</p>
            <div class="number d-flex justify-content-center align-items-center">
              <p>02</p>
              <Link to="/BusinessIntelligence" class="ml-2">
                Read More
              </Link>
            </div>
          </div>
          <div class="col-lg-3 p-lg-3">
            <i class="bx bx-braille"></i>
            <h2>Process Excellence</h2>
            <p>Process Engineering & Automation</p>
            <div class="number d-flex justify-content-center align-items-center">
              <p>03</p>
              <Link to="/ApplicationDevelopment" class="ml-2">
                Read More
              </Link>
            </div>
          </div>
          <div class="col-lg-3 p-lg-3">
            <i class="bx bxs-blanket"></i>
            <h2>AI,ML&VR</h2>
            <p>Artificial Intelligence & Machine Learning</p>
            <div class="number d-flex justify-content-center align-items-center">
              <p>04</p>
              <Link to="/Featured" class="ml-2">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="section5">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 p-lg-5">
              <h2 class="mt-2">
                Empowering Enterprises in their Digital Transformational
                Endeavours
              </h2>
              <Link to="/Contact" class="btn">Contact Us</Link>
            </div>
            <div class="col-lg-6 text-center p-lg-5">
              <img src={SVG2} class="img-fluid" alt="Xoogle" />
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5" id="section8">
        <div class="container">
          <h1>
            Areas of <span>Impact</span>
          </h1>
          <p>
            Defining Tasks | Resource Allocations | Project Risk Categorization
            Issues Escalations | Holistic Support
          </p>
          <div class="row text-center mt-4">
            <div class="col-lg-4">
              <i class="bx bx-upload mainIcon"></i>
              <h4>Transactional Improvements </h4>
              <ul>
                <li>
                  <i class="bx bx-caret-right"></i> Improve project engagement
                  visibility.
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Bring focus on key
                  profitability KPIs.
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Better visibility & Control
                  over project tasks and schedule.
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Deep exhaust and governance
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Tracking of project till the
                  last node
                </li>
              </ul>
            </div>
            <div class="col-lg-4">
              <i class="bx bx-trip mainIcon"></i>
              <h4>Transformational Journey</h4>
              <ul>
                <li>
                  <i class="bx bx-caret-right"></i> Can System Bucket the
                  priorities for projects / programs
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Can the system Intelligence
                  work as per our strategy & quickly improvise
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Can various KPIs be tracked and
                  alerts generated proactively
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> How Team Collaboration
                  multiplies the success effect
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> How do we engage resources
                  and project managers more effectively with lesser efforts
                </li>
              </ul>
            </div>
            <div class="col-lg-4">
              <i class="bx bxs-factory mainIcon"></i>
              <h4>Systems Alignment To Business</h4>
              <ul>
                <li>
                  <i class="bx bx-caret-right"></i> Single Source of Truth for
                  all  IT needs
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Maximum control over
                  variances
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Governance closely aligned
                  to profitability needs
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Better integrated MIS for
                  Management
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> RAID of projects
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Control & visibility over
                  resources
                </li>
                <li>
                  <i class="bx bx-caret-right"></i> Digital Channel for all real
                  time collaborative insights
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5" id="section7">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 p-lg-4">
              <img src={manImage} class="img-fluid" alt="Xoogle" />
            </div>
            <div class="col-lg-6 p-lg-4 mb-3">
              <h1>
                Process <span>Excellence</span>
              </h1>
              <ul class="mt-4">
                <li>
                  <i class="bx bx-check-circle"></i> Business Process
                  Engineering
                </li>
                <li>
                  <i class="bx bx-check-circle"></i> Business Process Automation
                </li>
                <li>
                  <i class="bx bx-check-circle"></i> End to End System
                  Implementation
                </li>
                <li>
                  <i class="bx bx-check-circle"></i> Training
                </li>
                <li>
                  <i class="bx bx-check-circle"></i> Change Management
                </li>
              </ul>
              <p>
              Companies adopt a host of different approaches to improving the quality and efficiency of their output: Total Quality Management, Business Process Reengineering, Lean, Six Sigma, Business Process Management, Business Performance Improvement and many more. All these methodologies have a focus on process as a common factor.
              </p>
              <Link to="/BusinessIntelligence" class="btn">Read More</Link>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="section9">
        <div class="container p-lg-5 ">
          <h1>
            Steps to <span>Success</span>
          </h1>
          <div class="row mt-5">
            <div class="col-lg-4">
              <div class="panel">
                <h3>
                  <i class="bx bxs-check-circle"></i> Cutting Edge Solutioning
                </h3>
                <p>Industry Expertise Process Expertise Vision Mapping.</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="panel">
                <h3>
                  <i class="bx bxs-check-circle"></i> Planning
                </h3>
                <p>
                  Agile Delivery Framework Milestones synced with business
                  objectives.
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="panel">
                <h3>
                  <i class="bx bxs-check-circle"></i>Best Practices
                </h3>
                <p>
                  Industry Standards Business Process Standard Delivery
                  methodologies
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-4">
              <div class="panel">
                <h3>
                  <i class="bx bxs-check-circle"></i> Quality & Security
                </h3>
                <p>
                  Multilevel quality and assurance Customer Excellence Security
                  protocols
                </p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="panel">
                <h3>
                  <i class="bx bxs-check-circle"></i> Change Management
                </h3>
                <p>Technology change and process Change Documentation</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="panel">
                <h3>
                  <i class="bx bxs-check-circle"></i> Training & Adoption
                </h3>
                <p>
                  Training – Power users  Training <br/> Skill upgradation
                  training
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="section4">
        <div class="container">
          <div class="row p-lg-5">
            <h1>
              <span>XOOGLE</span> QUALITY POLICY
            </h1>
            <p class="mt-4">
              We are devoted to accomplish 360 degrees Customer Delight by
              offering best in class solutions, delivered efficiently. provides
              a framework for quality objectives, and includes a commitment to
              meet applicable requirements. as well as to improve continuously.
            </p>
            <p>
              The Team strives to be the best in class provider of software
              development services in India and globally. It has implemented and
              achieved ISO9001 & 27001 certifications. Everyone at Xoogle is
              dedicated to focus on customer satisfaction by exceeding their
              expectations.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Landing;
